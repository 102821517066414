// External
import { Routes } from '@angular/router';

// Internal
import { CombinedMainGuard } from '../common/services/guards/combined.main.guard';
import { NavigationPublicGuard } from '../common/services/guards/navigation.public.guard';
import { DataResolver } from '../common/services/resolvers/data.resolver';
import { ServicesWebmailProtectionGuard } from '../common/services/guards/services-webmailprotection.guard';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'activity',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'services/lottery',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: '500',
        loadChildren: () => import('./../pages/500/internal-server-error.module').then(m => m.InternalServerErrorModule),
        canActivate: []
    },
    {
        path: '404',
        loadChildren: () => import('./../pages/404/page-not-found.module').then(m => m.PageNotFoundModule),
        canActivate: []
    },
    {
        path: 'home',
        loadChildren: () => import('./../pages/home/home.module').then(m => m.HomeModule),
        canActivate: [CombinedMainGuard],
        runGuardsAndResolvers: 'always',
        pathMatch: 'full'
    },
    {
        path: 'cookies',
        loadChildren: () => import('./../pages/services/central/cookies/cookies.module').then(m => m.CookiesModule),
        canActivate: []
    },
    {
        path: 'thirdparty',
        loadChildren: () => import('./../pages/services/central/thirdparty/thirdparty.module').then(m => m.ThirdPartyModule),
        canActivate: [],
        pathMatch: 'prefix'
    },
    {
        path: 'services/my/eol',
        loadChildren: () => import('./../pages/services/mybitdefender/eol/eol.module').then(m => m.EolModule),
        canActivate: []
    },
    {
        path: 'services/delete/pending',
        loadChildren: () => import('./../pages/services/central/delete/pending/delete.pending.module').then(m => m.DeletePendingModule),
        canActivate: []
    },
    {
        path: 'services/delete/confirm',
        loadChildren: () => import('./../pages/services/central/delete/confirm/delete.confirm.module').then(m => m.DeleteConfirmModule),
        canActivate: [CombinedMainGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'services/webmailprotection',
        loadChildren: () => import('../pages/services/services-webmailprotection/services-webmailprotection.module').then(m => m.ServicesWebmailProtectionModule),
        canActivate: [ServicesWebmailProtectionGuard],
        runGuardsAndResolvers: 'always'
    },
    // ! Removed for the moment
    // {
    //     path: 'services/lottery',
    //     loadChildren: () => import('./../pages/services/lottery/lottery.module').then(m => m.LotteryModule),
    //     canActivate: [CombinedMainGuard]
    // },
    {
        path: 'download',
        loadChildren: () => import('./../pages/services/download/download.module').then(m => m.DownloadModule),
        canActivate: [NavigationPublicGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'services/safe_zone',
        loadChildren: () => import('./../pages/services/safe-zone/safe-zone.module').then(m => m.SafeZoneModule),
        canActivate: [NavigationPublicGuard]
    },
    {
        path: 'services/parental/blocked',
        loadChildren: () => import('./../pages/services/parental/blocked/blocked.module').then(m => m.BlockedModule),
        canActivate: [NavigationPublicGuard]
    },
    {
        path: '',
        loadChildren: () => import('./../organiser/app.organiser.module').then(m => m.AppOrganiserModule),
        canActivate: [CombinedMainGuard],
        resolve: { data: DataResolver },
        runGuardsAndResolvers: 'always',
        pathMatch: 'prefix'
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];