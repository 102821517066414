import 'hammerjs';
import { CookieService } from 'ngx-cookie-service';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams, TranslatePipe } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UntypedFormBuilder } from '@angular/forms';
import * as Sentry from '@sentry/angular-ivy';
import { DatePipe } from '@angular/common';
import { Observable, catchError, of, map, forkJoin } from 'rxjs';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DateFormatPipe, SafePipe, LocalizedDatePipe, IdentitiesCounterPipe, OrderValuesByNamePipe, HourMinuteInterval} from '../app/common/pipes/pipes';
import { SharedModule } from '../app/common//components/shared.module';
import { AppRoutingModule } from './routing/routing.module';
import { NotTranslatedService } from './common/services/global/not-translated-keys-handler/not-translated.service';
import { TooltipService } from '../app/common/services/global/tooltip/tooltip.service';
import { PrivacyActionsService } from './pages/privacy/privacyActions.service';
import { DevicesService } from './common/services/process/devices/devices.service';
import { AuthService } from './common/services/global/auth/auth-service.service';
import { OnBoardingService } from './common/modal-dialog/privacy/onboarding-standard-modal/onboarding-modal-standard.service';
import { UiDropdownModule } from './common/components/ui/ui-dropdown/ui-dropdown.module';
import { EmptyStateModule } from './common/components/empty-state/empty-state.module';
import { UiToastModule } from './common/components/ui/ui-toast/ui-toast.module';
import { UiTooltipModule } from './common/components/ui/ui-tooltip/ui-tooltip.module';
import { DeactivateService } from './common/services/resolvers/deactivate.resolver';
import { LoaderInterceptor } from './common/services/interceptors/loader.interceptor';
import { LoaderService } from './common/services/core/loader.service';
import { UiAlertModule } from './common/components/ui/ui-alert/ui-alert.module';
import { UiIconModule } from './common/components/ui/ui-icon/ui-icon.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WrapperDeviceDetector } from './common/services/wrappers/wrapper-device-detector';
import { UiLoaderModule } from './common/components/ui/ui-loader/ui-loader.module';
import { PipesModule } from './common/pipes/pipes.module';
import { ConfigService, SentryDSN } from './common/config/config.service';
import { UiModalRoutelessModule } from './common/components/ui/ui-modal-routeless/ui-modal-routeless.module';
import { ServiceWorkerModule } from '@angular/service-worker'
@Injectable()
export class CentralHammerGestureConfig extends HammerGestureConfig {
    overrides = {
        'swipe': { velocity: 0.4, threshold: 20 } // override default settings
    } as any;
}

export class CentralTranslateHttpLoader implements TranslateLoader {
    constructor(private http: HttpClient, private prefix?: string, private suffix?: string) { }

    getTranslation(lang: string) : Observable<Object> {
        return forkJoin([
            this.http.get(`${this.prefix}platform/${lang}${this.suffix}`).pipe(
                catchError(err => {
                    console.log(`Locating in platform/${lang} has problems`);
                    return of({});
                })
            ),
            this.http.get(`${this.prefix}parental/${lang}${this.suffix}`).pipe(
                catchError(err => {
                    console.log(`Locating in parental/${lang} has problems`);
                    return of({});
                })
            ),
            this.http.get(`${this.prefix}nccparental/${lang}${this.suffix}`).pipe(
                catchError(err => {
                    console.log(`Locating in nccparental/${lang} has problems`);
                    return of({});
                })
            ),
            this.http.get(`${this.prefix}privacy/${lang}${this.suffix}`).pipe(
                catchError(err => {
                    console.log(`Locating in privacy/${lang} has problems`);
                    return of({});
                })
            ),
            this.http.get(`${this.prefix}subscriptions/${lang}${this.suffix}`).pipe(
                catchError(err => {
                    console.log(`Locating in subscriptions/${lang} has problems`);
                    return of({});
                })
            )
        ]).pipe(
            map(data => {
                let res = {};
                data.forEach(obj => {
                    res = {...res, ...obj};
                });
                return res;
            })
        );
    }
}

// translate
// ! let's try not to forget this!! ( in production is v2 )
export function createTranslateLoader(http: HttpClient) {
    return new CentralTranslateHttpLoader(http, './assets/localization/', '.json?m=' + environment.version);
}

export class CentralMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams): string {
        this.nts.notTranslated(params.key);
        return `**MISSING KEY**`;
    }

    constructor(private readonly nts: NotTranslatedService) { }
}

export const browserAnimationsDisabled = !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

// ngModule for app
@NgModule({
    declarations: [AppComponent],
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CentralMissingTranslationHandler,
                deps: [NotTranslatedService]
            },
            isolate: false
        }),
        AppRoutingModule,
        BrowserAnimationsModule.withConfig({disableAnimations: browserAnimationsDisabled}),
        HttpClientModule,
        HttpClientJsonpModule,
        UiModalRoutelessModule,
        UiDropdownModule,
        EmptyStateModule,
        UiToastModule,
        UiTooltipModule,
        SharedModule,
        UiIconModule,
        UiAlertModule,
        UiLoaderModule,
        PipesModule,
        ServiceWorkerModule
    ],
    providers: [
        CookieService,
        DateFormatPipe,
        TranslatePipe,
        DatePipe,
        LocalizedDatePipe,
        IdentitiesCounterPipe,
        TooltipService,
        NgModule,
        SafePipe,
        AuthService,
        DevicesService,
        NotTranslatedService,
        PrivacyActionsService,
        LoaderService,
        OnBoardingService,
        UntypedFormBuilder,
        DeactivateService,
        OrderValuesByNamePipe,
        HourMinuteInterval,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
        },
        {
            provide: HAMMER_GESTURE_CONFIG, useClass: CentralHammerGestureConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        {
            provide: DeviceDetectorService,
            useClass: WrapperDeviceDetector
        }
    ],
    bootstrap: [AppComponent],
    exports: []
})

export class AppModule {
    constructor(
        private readonly configService: ConfigService
    ) {
        // Sentry init based on config
        const _host = location.host;
        let _dsn;
        const _prodUrl = this.configService.getSiteUrl();
        const _parsedProdUrl = new URL(_prodUrl).hostname;

        if (_host === _parsedProdUrl) {
            _dsn = SentryDSN.CENTRAL;
        } else if (_host.indexOf('nmbapp.net') !== -1 || _host.indexOf('stage.bitdefender.com') !== -1 || _host.indexOf('test.bitdefender.biz') !== -1) {
            _dsn = SentryDSN.BETA_CENTRAL;
        } else {
            _dsn = null;
        }

        if (this.configService.getSentryAngular() && _dsn !== null) {
            Sentry.init({
                release: '3.21.1219.1',
                dsn: _dsn,
                integrations: [],
                tracesSampleRate: 0.5,
                autoSessionTracking: true, // default: true
                ignoreErrors: [
                    /Uncaught \(in promise\): ChunkLoadError: Loading chunk .* failed[\s\S]*/,
                    /Failed to load script https:\/\/assets.adobedtm.com\/extensions\/.*.min.js/
                ]
            });
        }
    }
 }

