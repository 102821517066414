export enum ServiceType {
    SUBSCRIPTION = 'subscription',
    LICENSE = 'license'
}
export class BundleModel {
    service_id: string;
    service_type: string;
    type: string;
    life_cycle: string;
    commercial_id: string;
    created: number;
    metadata?: {
        billing_cycle: number,
        billing_date: number,
        billing_cycle_price: number,
        billing_period?: string,
        currency: string,
        platform_currency?: string,
        platform_name?: string,
        platform_subscription_id?: string,
        platform_sub_start_date?: number,
        platform_term_start_date?: number,
        platform_renewal_number?: number,
        auto_renew?: boolean
    };
    end_date: number;
    bundle_id: string;
    bundle_friendly_name: string;
    max_shares?: number;
    slots: number;
    applications: AppModel[];
    status: string;
    accounts: any[];
    active_slots: number;
    server_time: number;
    interface: {
        buttons: any;
        allocation: any,
        name: string;
        renewalDate: number;
        renewalDateLocale: string;
        billingDateLocale: string;
        endDateLocale: string;
        price: number;
        currency: string;
        daysRemaining: number;
        devices: string[],
        devicesNoBox: number,
        totalDevices: number;
        expired: boolean;
        unlimitedSlots:boolean;
        image: string;
        mainDesc:string;
        description: string;
        type: string;
        serviceType: string;
        serviceTypeLocal: string;
        life_cycle: string;
        billing_cycle?: string;
        desc: string;
        end_date: number;
        buyOrRenew?: any;
        hideEndDate?: boolean;
        startPlan?: any;
        personalPlan?: any;
        familyPlan?: any;
        ultimatePlan?: any;
        install?: any;
        expiryAndUsage?: any;
        badge?: any;
        manageButtons?: any;
        showConvert?: boolean;
        showBuyMoreSlotsLink?: boolean;
        details?: any;
        invites?: any;
    }
    devicesShownIds?: string[];
    devicesRemovedIds?: string[];
    devices?: DeviceModel[];
    manageButtons?: ManageButtonModel[];
    newEndDate?: string;
    endingInExtendService?: number;
    endingInNewService?: number;
    endDateFormatted?: string;
    check?: any;
    plan_name?: string;
    strings?: any;
    processed: any;
    source?: any;
}

export class AppModel {
    app_id: string;
    countable: number;
    slots: {
        max: number;
        min: number;
    };
    app_params?: {
        level: string;
        connections?: number;
    };
    commercial_id_info?: {
        buyts_url: string;
    };
    interface?: any;
    devices?: DeviceModel[];
    nameToBeDisplayed?: any;
    numberOfDevices?: number;
    os?: string;
    icon?: string;
    check?: any;
    installIncentive?: any;
    strings?: any;
}

export class MacVendorModel {
    mac: string;
    vendor: string;
}

export class ProtectionStatusModel {
    issues: number;
    protected: number;
    protection_app: string;
}

export class SubscriptionInfoModel {
    commercial_id: string;
    free_slots: number;
    remaining_days: number;
    type: string;
}

export interface ISharedSubscriptionInfo {
    hasSharedSubscription: boolean;
    isPayer: boolean;
    payerEmail: string;
    payerName?: string;
    showAlert?: boolean;
    isSecondaryUser?: boolean;
}

export class DeviceModel {
    agent_detected_name?: string;
    agent_status?: number;
    apps?: AppModel[];
    box_managed?: number;
    created?: number;
    device_account_sid?: number;
    device_icon?: string;
    device_id?: string;
    device_os?: string;
    device_state?: string;
    device_type?: string;
    display_name?: string;
    install_agent?: number;
    mac_vendor?: MacVendorModel;
    macs?: number[];
    profile_id?: number;
    protection_status?: ProtectionStatusModel;
    subscription_info?: SubscriptionInfoModel;
    current_bundle?: BundleModel;
    bundles_on_change?: BundleModel[];
    removed?: boolean;
}

export class BundlesServicesModel {
    license: BundleModel[];
    subscription: BundleModel[];
}

export class ManageButtonModel {
    button_name: string;
    modal_name?: string;
    action?: string;
}

export class InvoiceModel {
    id: string;
    accountId: string;
    accountNumber: string;
    accountName: string;
    invoiceDate: string;
    invoiceNumber: string;
    dueDate: string;
    invoiceTargetDate: string;
    amount: number;
    balance: number;
    creditBalanceAdjustmentAmount: number;
    createdBy: string;
    status: string;
    reversed: boolean;
    body: string;
    CollectionStatus__c: string;
    CollectionDate__c: string;
    InvoiceType__c: string;
    invoiceItems: string;
    invoiceFiles: string;
}

export class DeviceAllocationMgmtResponseModel {
    id: number;
    jsonrpc: string;
    result: {
        status: number;
    }
}

export class WantedServiceModel {
	service_id: string;
	device_id: string;
    app_ids: string[];
}

export class SubscriptionStatesModel {
    upgradable: boolean;
    ending: boolean;
    expired: boolean;
    notContinous: boolean
}

export class RenewalInfoModel {
    Email: string;
    MerchantCode: string;
    AvangateAccount: string;
    SubscriptionReference: string;
    ExpirationDate: string;
    RecurringEnabled: boolean;
    ProductName: string;
    AvangateProductId: string;
    WebgenProductId: number;
    BundleId: string;
}

export class IFrameRequestDetailsModel {
    sub_id: string;
    account: string;
}

export class ZuoraConfigObject {
    key: string;
    country: string;
    debug: boolean;
    element: string;
    return_url: string;
    default_scenario: string;
}

export class RedeemDataModel {
    status: number;
    reason: string;
    data: {
        strings?: any;
        service_type: string;
        type: string;
        life_cycle: string;
        commercial_id: string;
        bundle_id: string;
        bundle_friendly_name: string;
        slots: number;
        applications: AppModel[];
        status: string;
        metadata: any;
        end_date: number;
        compatible_services: string[];
        endingInNewService?: number;
        // endDateFormatted?: string;
        // image?: string;
    };
    endDateFormatted?: any;
    image?: string;
    daysRemaining?: any;
    serviceTypeLocal?: any;
    hideEndDate?: any;
    server_time?: number;
}

export enum AllocationCases {
    LIMIT_REACHED = 'limitReached',
    ABOVE_INTERMIDIATE = 'aboveIntermidiate',
    ABOVE_INTERMIDIATE_BOX = 'aboveIntermidiateBox',
    UNDER_INTERMIDIATE = 'underIntermidiate',
    MORE_THAN_ONE = 'moreThanOne',
    ONE = 'one',
    ZERO = 'zero'
}

export enum DeviceAllocationDisplayComponents {
    STEP1_HEADER = 'step1header',
    STEP2_REMOVE = 'step2remove',
    STEP2_USAGE = 'step2usage',
    STEP2_WILL_HAVE = 'step2willHave',
    STEP2_WILL_HAVE_SINGLE = 'step2willHaveSingle',
    STEP2_WILL_HAVE_MULTI = 'step2willHaveMulti',
    USED_BY_BOX = 'usedByBox',
    SERVICE_WILL_END = 'serviceWillEnd',
}
export class DeviceAllocationDisplayByServiceTypeCell {
    [ServiceType.LICENSE]: string;
    [ServiceType.SUBSCRIPTION]?: string;
}
export class DeviceAllocationDisplayCell {
    [DeviceAllocationDisplayComponents.STEP1_HEADER]: DeviceAllocationDisplayByServiceTypeCell;
    [DeviceAllocationDisplayComponents.STEP2_REMOVE]: DeviceAllocationDisplayByServiceTypeCell;
    [DeviceAllocationDisplayComponents.STEP2_USAGE]: DeviceAllocationDisplayByServiceTypeCell;
    [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]?: DeviceAllocationDisplayByServiceTypeCell;
    [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_SINGLE]?: DeviceAllocationDisplayByServiceTypeCell;
    [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_MULTI]?: DeviceAllocationDisplayByServiceTypeCell;
    [DeviceAllocationDisplayComponents.USED_BY_BOX]: DeviceAllocationDisplayByServiceTypeCell;
    [DeviceAllocationDisplayComponents.SERVICE_WILL_END]: DeviceAllocationDisplayByServiceTypeCell;
}
export class ServiceApplicationsDevicesAllocationCases {
    [AllocationCases.LIMIT_REACHED]: string;
    [AllocationCases.ABOVE_INTERMIDIATE]: string;
    [AllocationCases.ONE]: string;
    [AllocationCases.UNDER_INTERMIDIATE]: string;
    [AllocationCases.ZERO]: string;
}
export class ServiceDevicesAllocationCases {
    [AllocationCases.LIMIT_REACHED]: DeviceAllocationDisplayCell;
    [AllocationCases.ABOVE_INTERMIDIATE]: DeviceAllocationDisplayCell;
    [AllocationCases.ABOVE_INTERMIDIATE_BOX]: DeviceAllocationDisplayCell;
    [AllocationCases.ONE]: DeviceAllocationDisplayCell;
    [AllocationCases.UNDER_INTERMIDIATE]: DeviceAllocationDisplayCell;
    [AllocationCases.ZERO]: DeviceAllocationDisplayCell;
}
export class ServiceSlotsAllocationCases {
    [AllocationCases.LIMIT_REACHED]: ServiceDevicesAllocationCases;
    [AllocationCases.MORE_THAN_ONE]: DeviceAllocationDisplayCell;
    [AllocationCases.ONE]: DeviceAllocationDisplayCell;
}
