import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import { ModalContainerOptions, ModalProperties } from '../../../../common/services/global/modal/Modal.model';

/* Should be used all over the place when opening a modal but is too much to solve it for this story */
export interface ContainerOptions {
    service_id: string;
    scenario: string;
    currentStep: string;
    profileHash: number;
    profile_id: string;
    device_id: string;
    cidCode: string;
    bundle_id: string;
}

@Injectable({
    providedIn: 'root'
})

export class ModalRoutelessService {

    openEventBroadcaster: ReplaySubject<any> = new ReplaySubject(1);
    closeEventBroadcaster: Subject<string> = new Subject();
    animationStatusBroadcaster = new BehaviorSubject<boolean>(false);

    modalsBufferStorage = {};
    availableModalInfos = {};
    modalProperties: ModalProperties = {
        activated: false,
        modalActivated: null,
        modalOpening: false,
        activeStepInActiveModal: null,
        isAnimating: false
    }

    constructor() {}

    /**
     * Method used to close modal container
     */
    close(newRoute?: string) {
        this.closeEventBroadcaster.next(newRoute);
    }

    /**
     * Method used to open modal container
     * @param {ModalName} name Name of the component to be injected
     * @param {ModalContainerOptions} containerOptions Modal styling options. Omit this param if none of the styling options are different from default ones:
     * * size?: ModalSize.MD
     * * backdropDismissable?: true
     * * buttonDismissable?: true
     * * exceptionClass?: string
     * * isFlow?: false
     * * reverseAnimation?: false
     * @param contentOptions
     * @param override
     * @param options
    */
    open(name: ModalName, containerOptions? : ModalContainerOptions, contentOptions?, override?:boolean, options?) {
        if (this.modalProperties.activated && !override) {
            this.availableModalInfos[name] = { name: name, containerOptions: containerOptions, contentOptions: contentOptions };
        } else {
            this.availableModalInfos[name] = { name: name, containerOptions: containerOptions, contentOptions: contentOptions };
            if (override) {
                //> Stergem datele despre modala care se inchide
                delete this.availableModalInfos[this.modalProperties.modalActivated];
            }
            this.modalProperties.activated = true;
            this.modalProperties.modalActivated = name;
            this.modalProperties.modalOpening = true;
            this.openEventBroadcaster.next({ name: name, containerOptions: containerOptions, contentOptions: contentOptions, options: options });
        }
    }

    resetVarsOnClose() {
        delete this.availableModalInfos[this.modalProperties.modalActivated];
        this.modalProperties.activated = false;
        this.modalProperties.modalOpening = false;
        this.modalProperties.modalActivated = null;
    }

    getPendingModal() {
        return this.modalProperties.modalActivated;
    }

    getModalInfo(modal: ModalName) {
        return this.availableModalInfos[modal];
    }

    /**
     * Method used to get modal content options
     * @param modal The modal name
     * @returns Th modal content options
     */
    public getModalContentOptions(modal: ModalName): any {
        return this.availableModalInfos?.[modal]?.contentOptions ?? {};
    }

    getIsModalOpened() {
        return this.modalProperties.activated;
    }

    setBufferItem(key, value) {
        this.modalsBufferStorage[key] = value;
    }

    getBufferItem(key) {
        return this.modalsBufferStorage[key];
    }

    setActiveStep(value) {
        this.modalProperties.activeStepInActiveModal = value;
    }

    getActiveStep() {
        return this.modalProperties.activeStepInActiveModal;
    }

    setIsAnimating(value) {
        this.modalProperties.isAnimating = value;
        this.animationStatusBroadcaster.next(value);
    }

    getIsAnimating() {
        return this.modalProperties.isAnimating;
    }
}

export enum ModalName {
    installModal = 'install-modal',
    installProtectionModal = 'install-protection-modal',
    wifiSettingsModal = 'wifi-settings-modal',
    settingsDeviceModal = 'settings-device-modal',
    whitelistModal = 'whitelist-modal',
    resetSettingsModal = 'reset-settings-modal',
    manageDuplicatesModal = 'manage-duplicates-modal',
    changeLanguageModal = 'change-language-modal',
    infoModal = 'info-modal',
    billingDetailsModal = 'billing-details-modal',
    videoBoxModal = 'video-box-modal',
    whatCanYouDoModal = 'what-can-you-do-modal',
    manageProfileModal = 'manage-profile-modal',
    manageDevicesModal = 'manage-devices-modal',
    removeProfileModal = 'remove-profile-modal',
    installParentalModal = 'install-parental-modal',
    aboutProtectionModal = 'about-protection-modal',
    connectGmailModal = 'connect-gmail-modal',
    protectionScoreRiskMapModal = 'protection-score-risk-map-modal',
    protectionScoreHistoryModal = 'protection-score-history-modal',
    surveyModal = 'survey-modal',
    manageDataModal = 'manage-data-modal',
    manageBrokersModal = 'manage-brokers-modal',
    footprintExposureShowMoreModal = 'footprint-exposure-show-more-modal',
    footprintUsingServiceModal = 'footprint-using-service-modal',
    potentialDigitalFootprint = 'potential-digital-footprint-modal',
    identityMonitorModal = 'identity-monitor-modal',
    onboardingStandardNameModal = 'onboarding-standard-name-modal',
    onboardingStandardEmailModal = 'onboarding-standard-email-modal',
    onboardingStandardChoosePhoneModal = 'onboarding-standard-choose-phone-modal',
    onboardingStandardPhoneModal = 'onboarding-standard-phone-modal',
    onboardingDpyStatusModal = 'onboarding-dpy-status-modal',
    onboardingStandardDpyStatusModal = 'onboarding-standard-dpy-status-modal',
    breachesRiskModal = 'breaches-risk-modal',
    redeemConfirmationModal = 'redeem-confirmation-modal',
    serviceDetailsModal = 'service-details-modal',
    serviceInvoicesModal = 'service-invoices-modal',
    deviceAllocationModal = 'device-allocation-modal',
    endServiceModal = 'end-service-modal',
    endServiceConfirmationModal = 'end-service-confirmation-modal',
    reactivateServiceModal = 'reactivate-service-modal',
    paymentDetailsModal = 'payment-details-modal',
    onboardingMsp = 'onboarding-msp-modal',
    removeBoxModal = 'remove-box-modal',
    scheduleCallModal = 'schedule-call-modal',
    wrongLangModal = 'wrong-lang-modal',
    manageExceptionsModal = 'manage-exceptions-modal',
    removeDeviceModal = 'remove-device-modal',
    setPortFwdModal = 'set-port-fwd-modal',
    setIpModal = 'set-ip-modal',
    editProfileModal = 'edit-profile-modal',
    fixIssuesModal = 'fix-issues-modal',
    antitheftModal = 'antitheft-modal',
    vulnerabilityScanModal = 'vulnerability-scan-modal',
    oneclickOptimizerModal = 'oneclick-optimizer-modal',
    malwareScanModal = 'malware-scan-modal',
    changeEmailModal = 'change-email-modal',
    deleteAccountModal = 'delete-account-modal',
    referralCongratsModal = 'referral-congrats-modal',
    startTrialModal = 'start-trial-modal',
    convertToSubscriptionModal = 'convert-to-subscription-modal',
    subscriptionsV3DevicesModal = 'subscriptions-v3-devices-modal',
    onboardingModal = 'onboarding-modal',
    changeCategoryStatusModal = 'change-category-status-modal',
    deleteProfileModal = 'delete-profile-modal',
    editDeleteProfileModal = 'edit-delete-profile-modal',
    mapLocateModal = 'map-locate-modal',
    removeExceptionModal = 'remove-exception-modal',
    setRoutinesModal = 'set-routines-modal',
    resetToRecommendedSettingsModal = 'reset-to-recommended-settings-modal',
    addExceptionModal = 'add-exception-modal',
    assignDeviceModal = 'assign-device-modal',
    unassignDeviceModal = 'unassign-device-modal',
    addTopicExceptionsModal = 'add-topic-exceptions-modal',
    timeExtensionModal = 'time-extension-modal',
    inviteServiceModal = 'invite-service-modal',
    removeSubscriptionMemberModal = 'remove-subscription-member-modal',
    removeMetadataModal = 'remove-metadata-modal',
    sharedFamilyPlanModal = 'shared-family-plan-modal'
}
